<template>
  <h2>페이지를 찾을 수 없습니다!</h2>

</template>

<script>
export default {
  title: '찾을 수 없는 페이지',
  name: 'NotFound',
};
</script>

<style>

</style>
